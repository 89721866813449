import React, { useEffect, useState } from "react";
// import Dashboard from './pages/Dashboard';
import DashboardLayout from './layout/dashboardLayout';
import Invoice from './pages/Invoice';
// import Balance from './pages/Balance';
import Login from './pages/Authentication/Login';
import Register from './pages/Authentication/Register';
import AddBeneficiary from './pages/Dashboard/Benificiary/addBenificiary';
import Beneficiaries from './pages/Dashboard/Benificiary/beneficiaries';
import TransactionDetails from './pages/Transactions/transactionDetails';
import TransactionsHistory from './pages/Transactions/transactionHistory';
import BusinessPlans from './pages/Plans/businessPlans';
import Profile from './pages/Profile';
import AccountConfirmation from './pages/Account/accountConfirmation';

import { BrowserRouter, Route, Routes, useLocation, Navigate, json, useNavigate } from 'react-router-dom';
import Balance from './pages/Balance';
import CorporateDocuments from './pages/Account/corporateDocuments';
import CompanyBoardMembers from './pages/Account/companyBoardMembers';
import CompanyProfile from './pages/Account/companyProfile';
import CompanyShareHolders from "./pages/Account/shareHolders";
import CompanyShareHoldersList from "./pages/Account/shareHoldersList";
import OpenCompanyAccount from "./pages/Account/openAccount";
import VerifyOTP from "./pages/Account/verifyOTP";
import VerifyOTPLogin from "./pages/Account/verifyOTPLogin";
import NotFound from "./pages/notFound/notFound";
import NotificationApproval from "./pages/Authentication/notificationApproval";
import BusinessHome from "./pages/Business/Home";
import Pricing from "./pages/Business/Pricing";
import CryptoCurrency from "./pages/Crypto/cryptoCurrency";
import Wallet from "./pages/Crypto/wallet";
import POSTransactionsHistory from "./pages/POS/posTransactionsHistrory";
import { ThemeProvider } from "./pages/Theme/themeContext";
import POSPaymentHistory from "./pages/POS/posPaymentHistory";
import UpdateShareHolder from "./pages/Account/updateShareHolder";
import { whitelabel_id } from "./constants/ids";
import apiCall from "./utils/apiCall";
import { API_ENDPOINTS, API_METHODS } from "./constants/endpoints";
import ContactUs from "./pages/Business/contact/contactUs";
import TermsAndConditions from "./pages/Business/termsAndConditions/tc";
import PrivacyPolicy from "./pages/Business/privacyPolicy/privacy";
import ForgetPassword from "./pages/Account/forgetPassword";
import WebForgotVerify from "./pages/Account/webForgotVerify";
import ResetPassword from "./pages/Account/resetPassword";
import AcceptPlan from "./pages/IBANPlanAcceptReject/accept";
import DeclinePlan from "./pages/IBANPlanAcceptReject/decline";
import UpdatedPlanAccept from "./pages/IBANPlanAcceptReject/updatePlanAccept";
import UpdatedPlanReject from "./pages/IBANPlanAcceptReject/updatePlanReject";
import ApproveLogin from "./pages/Authentication/approveLogin";
import DeclineLogin from "./pages/Authentication/declineLogin";





function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const $ = window.jQuery
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("auth_user")) || null)
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('')
  const [config, setConfig] = useState({})
  const [faviconUrl, setFaviconUrl] = useState(null)
  const [siteName, setSiteName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [metaContent, setMetaContent] = useState('')
  useEffect(() => {

    const scriptUrls = [
      "js/custom.min.js",
      "js/dlabnav-init.js",

    ];

    scriptUrls.forEach((scriptUrl) => {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.async = true;
      document.body.appendChild(script);
    });
    // Load each script asynchronously

    return () => {
      // Clean up: Remove the script elements when the component unmounts
      scriptUrls.forEach((scriptUrl) => {
        const script = document.querySelector(`[src="${scriptUrl}"]`);
        if (script) {
          script.remove();
        }
      });
    };
  }, []);
  const getConfigSettings = async () => {
    setLoading(true)
    const url = API_ENDPOINTS.GET_CONFIG_SETTINGS
    const body = {
      whitelabel_id: whitelabel_id,
    }
    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      sessionStorage.setItem('configSettings', JSON.stringify(response?.config))
      const data = JSON.stringify(response?.config)
      setConfig(response?.config)
      setFaviconUrl(response?.config?.site_favicon)
      setSiteName(response?.config?.site_name)

      setLoading(false)

    } else if (response?.status == 0) {
      console.error(response?.message)
      setLoading(false)

    } else {
      console.error('Unable to fetch.')
    }
  }
  useEffect(() => {
    getConfigSettings()
    getMetaContent()
  }, [])
  useEffect(() => {
    setTimeout(function () {
      $('#preloader').remove();
      $('#main-wrapper').addClass('show');
    }, 800);
  }, [pathname])

  useEffect(() => {
    // Update the favicon dynamically
    const faviconLink = document.getElementById('favicon');
    const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
    if (faviconLink) {
      faviconLink.href = faviconUrl;
      ogImageMetaTag.setAttribute('content', faviconUrl);
    }
    if (siteName) {
      document.title = siteName;
    }
  }, [faviconUrl, siteName]);

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (config && metaContent) {
      if (location.pathname == '/') {
        document.title = config?.site_name + ": " + metaContent?.home?.title;
      } else if (location.pathname == '/login') {
        document.title = config?.site_name + ": " + metaContent?.login?.title;
        if (ogTitle) {
          ogTitle.setAttribute('content', metaContent?.login?.title);
        }
        if (ogDescription) {
          ogDescription.setAttribute('content', metaContent?.login?.description);
        }
        if (metaDescription) {
          metaDescription.setAttribute('content', metaContent?.login?.description);
        }
      } else if (location.pathname == '/forget-password') {
        document.title = config?.site_name + ": " + metaContent?.forgot_password?.title;
        if (ogTitle) {
          ogTitle.setAttribute('content', metaContent?.forgot_password?.title);
        }
        if (ogDescription) {
          ogDescription.setAttribute('content', metaContent?.forgot_password?.description);
        }
        if (metaDescription) {
          metaDescription.setAttribute('content', metaContent?.forgot_password?.description);
        }
      } else if (location.pathname == '/privacy-policy') {
        document.title = config?.site_name + ": " + metaContent?.privacy?.title;
        if (ogTitle) {
          ogTitle.setAttribute('content', metaContent?.privacy?.title);
        }
        if (ogDescription) {
          ogDescription.setAttribute('content', metaContent?.privacy?.description);
        }
        if (metaDescription) {
          metaDescription.setAttribute('content', metaContent?.privacy?.description);
        }
      } else {
        document.title = config?.site_name
        metaDescription.setAttribute('content', 'CodeGo : CodeGo Business Banking');
      }
    }

  }, [location, config, metaContent])

  const getMetaContent = async () => {
    setLoading(true)
    const url = API_ENDPOINTS.GET_META_CONTENT
    const body = {
      whitelabel_id: whitelabel_id,
    }
    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      sessionStorage.setItem('metaContent', JSON.stringify(response?.content_data))
      setMetaContent(response?.content_data)
      console.log(location.pathname)

      setLoading(false)

    } else if (response?.status == 0) {
      console.error(response?.message)
      setLoading(false)

    } else {
      console.error('Unable to fetch.')
    }
  }
  return (
    <ThemeProvider>
      <Routes>
        {/* <Route path="/" exact element={<PrivateRoute element={<DashboardLayout title={"Balance"}><Balance /></DashboardLayout>} />} /> */}
        <Route path="/balance" element={<PrivateRoute element={<DashboardLayout title={"Balance"}><Balance /></DashboardLayout>} />} />
        <Route path="/profile" element={<PrivateRoute element={<DashboardLayout title={"Business Profile"}><Profile /></DashboardLayout>} />} />
        <Route path="/plans" element={<PrivateRoute element={<DashboardLayout title={"Business Plans"}><BusinessPlans /></DashboardLayout>} />} />
        <Route path="/corporate-documents" element={<PrivateRoute element={<DashboardLayout title={"Corporate Documents"}><CorporateDocuments /></DashboardLayout>} />} />
        <Route path="/company-board-members" element={<PrivateRoute element={<DashboardLayout title={"Company Director"}><CompanyBoardMembers /></DashboardLayout>} />} />
        <Route path="/company-share-holders" element={<PrivateRoute element={<DashboardLayout title={"Share Holders"}><CompanyShareHolders /></DashboardLayout>} />} />
        <Route path="/company-share-holders-list" element={<PrivateRoute element={<DashboardLayout title={"Share Holders"}><CompanyShareHoldersList /></DashboardLayout>} />} />
        <Route path="/update-share-holder/:id" element={<PrivateRoute element={<DashboardLayout title={"Share Holders"}><UpdateShareHolder /></DashboardLayout>} />} />
        <Route path="/company-profile" element={<PrivateRoute element={<DashboardLayout title={"Company Profile"}><CompanyProfile /></DashboardLayout>} />} />
        <Route path="/account-confirmation" element={<PrivateRoute element={<DashboardLayout title={"Dashboard"} ><AccountConfirmation /></DashboardLayout>} />} />
        <Route path="/beneficiaries" element={<PrivateRoute element={<DashboardLayout title={"Beneficiaries"}><Beneficiaries /></DashboardLayout>} />} />
        <Route path="/add-beneficiary" element={<PrivateRoute element={<DashboardLayout title={"Beneficiaries"}><AddBeneficiary /></DashboardLayout>} />} />
        <Route path="/transactions-history" element={<PrivateRoute element={<DashboardLayout title={"Transactions"}><TransactionsHistory /></DashboardLayout>} />} />
        <Route path="/pos-transactions-history" element={<PrivateRoute element={<DashboardLayout title={"POS Transactions"}><POSTransactionsHistory /></DashboardLayout>} />} />
        <Route path="/pos-payment-history" element={<PrivateRoute element={<DashboardLayout title={"POS Payment"}><POSPaymentHistory /></DashboardLayout>} />} />
        <Route path="/crypto-currency" element={<PrivateRoute element={<DashboardLayout title={"Crypto"}><CryptoCurrency /></DashboardLayout>} />} />
        <Route path="/wallet/:coin" element={<PrivateRoute element={<DashboardLayout title={"Crypto"}><Wallet /></DashboardLayout>} />} />
        {/* <Route path="/transactions-details/:id" element={<PrivateRoute element={<DashboardLayout title={"Transaction"}><TransactionDetails /></DashboardLayout>} />} /> */}


        <Route path="/noti-app" element={<NotificationApproval />} />        
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<NonPrivateRoute element={<Login />} />} />
        <Route path="/register" element={<NonPrivateRoute element={<Register />} />} />
        <Route path="/open-account" element={<NonPrivateRoute element={<OpenCompanyAccount />} />} />
        <Route path="/verify-otp" element={<NonPrivateRoute element={<VerifyOTP />} />} />
        <Route path="/verify-otp-login" element={<NonPrivateRoute element={<VerifyOTPLogin />} />} />
        <Route path="/" element={<NonPrivateRoute element={<BusinessHome />} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/forget-verify" element={<WebForgotVerify />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/accept-plan/:unique_id" element={<AcceptPlan />} />
        <Route path="/decline-plan/:unique_id" element={<DeclinePlan />} />
        <Route path="/update-plan-accept/:unique_id" element={<UpdatedPlanAccept />} />
        <Route path="/update-plan-reject/:unique_id" element={<UpdatedPlanReject />} />
        <Route path="/approve-login/:unique_id" element={<ApproveLogin />} />
        <Route path="/decline-login/:unique_id" element={<DeclineLogin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </ThemeProvider>
  );
}
const PrivateRoute = ({ element }) => {
  let isAuthenticated = false
  const authUser = JSON.parse(localStorage.getItem('auth_user'))
  const activeSession = JSON.parse(sessionStorage.getItem('auth_user'))
  if (!activeSession) {
    localStorage.removeItem('auth_user')
  }
  if (!authUser) {
    isAuthenticated = false
  } else {
    const { web_token } = authUser
    isAuthenticated = !!web_token;
  }

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/" replace={true} state={{ from: window.location.pathname }} />
  );
};

const NonPrivateRoute = ({ element }) => {
  const authUser = JSON.parse(localStorage.getItem('auth_user'));
  const activeSession = JSON.parse(sessionStorage.getItem('auth_user'))

  if (!activeSession) {
    localStorage.removeItem('auth_user')
  }
  const isAuthenticated = authUser && authUser.web_token;
  return isAuthenticated ? (
    <Navigate to="/" replace={true} />
  ) : (
    element
  );
};
export default App;

{/* <Route path="/" exact element={<DashboardLayout><Balance /></DashboardLayout>} /> */ }
{/* <Route path="/balance" element={<DashboardLayout><Balance /></DashboardLayout>} /> */ }
{/* <Route path="/profile" element={<DashboardLayout><Profile /></DashboardLayout>} /> */ }
{/* <Route path="/plans" element={<DashboardLayout><BusinessPlans /></DashboardLayout>} /> */ }
{/* <Route path="/corporate-documents" element={<DashboardLayout><CorporateDocuments /></DashboardLayout>} /> */ }
{/* <Route path="/company-board-members" element={<DashboardLayout><CompanyBoardMembers /></DashboardLayout>} /> */ }
{/* <Route path="/company-share-holders" element={<DashboardLayout><CompanyShareHolders /></DashboardLayout>} /> */ }
{/* <Route path="/company-share-holders-list" element={<DashboardLayout><CompanyShareHoldersList /></DashboardLayout>} /> */ }
{/* <Route path="/company-profile" element={<DashboardLayout><CompanyProfile /></DashboardLayout>} /> */ }
{/* <Route path="/account-confirmation" element={<DashboardLayout><AccountConfirmation /></DashboardLayout>} /> */ }
{/* <Route path="/beneficiaries" element={<DashboardLayout><Beneficiaries /></DashboardLayout>} /> */ }
{/* <Route path="/add-beneficiary" element={<DashboardLayout><AddBeneficiary /></DashboardLayout>} /> */ }
{/* <Route path="/transactions-history" element={<DashboardLayout><TransactionsHistory /></DashboardLayout>} /> */ }
{/* <Route path="/transactions-details" element={<DashboardLayout><TransactionDetails /></DashboardLayout>} /> */ }