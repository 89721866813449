import { useEffect } from "react";
import { Link } from "react-router-dom";

const Section1 = ({content}) => {
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",
            
        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    const handleRedirect = (link) => {
        window.location.href = link;
        window.location.reload();

    };
    return (
        <div className="row mt-5 w-75 mx-auto" style={{ paddingTop: "100px" }}>
            <div className="col-xl-6 ">
                <h1 style={{ fontSize: '80px', lineHeight: '90px', width: "90%", minWidth: '450px' }} className="">
                   {content?.banner_title}
                </h1>
                <p style={{ fontSize: '18px', minWidth: '450px' }} className="w-75">
                    {content?.banner_subtitle}
                </p>
                <div>
                <Link to={"/open-account"}>  <button  type="button" class="btn light  btn-success">OPEN ACCOUNT</button></Link>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="bounce-animation mt-12 rounded-full w-[500px] h-[500px] mx-auto  d-flex justify-content-between align-items-center"
                    style={{ borderStyle: "dashed", borderWidth: '2px', width: '500px', height: '500px', borderColor: '#3B82F6', borderRadius: '9999px', marginRight: 'auto' }}
                >
                    <div className=" " style={{ position: 'relative', width: '450px', height: "300px", marginRight: 'auto' }}>
                        <img className="  " style={{ width: '450px', height: "300px" }} src={content?.banner_image} alt="" />
                        <img className=" " style={{ position: 'absolute', left: "0px", bottom: "-2.5rem", zIndex: -10 }} src="https://codegopay.com/business/assets/business_panel/img/hero/hero-shape-3-2.png" alt=""></img>
                        <img className="" style={{ position: 'absolute', right: 0, top: "1.25rem", zIndex: -10 }} src="https://codegopay.com/business/assets/business_panel/img/hero/hero-shape-3-1.png" alt=""></img>
                    </div>

                </div>
            </div>
        </div >
    )
}
export default Section1