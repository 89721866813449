import { useEffect } from "react";
import { useTheme } from "../../Theme/themeContext";
const Section3R = ({ content }) => {
    const { lightTheme, toggleTheme } = useTheme();
   
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    const image = "https://res.cloudinary.com/hashfort/image/upload/v1698055930/pagecontent/nvj3necwf7gzd5pfpomo.png"
    return (
        <div className="row mt-5 business-container mx-auto" style={{ paddingTop: "100px" }}>
            <div className="col-xl-6 text-center">
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '10%' }}>
                    <img
                        className="my-auto responsive-image-business"

                        src={content?.section_3_left_image}
                        alt=""
                    />
                </div>

            </div>
            <div className="col-xl-6 mt-sm-5 mt-md-5">
                <div className="w-75 w-sm-100 mx-auto mt-5 mt-lg-0" >
                    {/* <h1 style={{ fontSize: '40px', lineHeight: '44px' }}>{content?.section_3_title}</h1>
                    <p className="mt-5">Our onboarding process is superfast, transparent, simple and 100% online – just like our banking platform.</p>
                    <p className="">Open account in under 10 minutes:</p>
                    <div>
                        <span>2 minutes - Create User</span><br />
                        <span>3 minutes - Fill in Questionnaire</span><br />
                        <span>1 minute - Upload Documents</span><br />
                        <span>2 minutes - Create User</span><br />
                    </div> */}
                    <div dangerouslySetInnerHTML={{ __html: content?.section_3_description }} className={lightTheme ? 'black-text' : "white-text"} />
                </div>
            </div>
        </div>
    )
}
export default Section3R;