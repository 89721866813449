import { useEffect, useState } from "react"
import BusinessNavbar from "../Home/businessNavbar"
import Footer from "../Home/footerBusiness"
import { whitelabel_id } from "../../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import apiCall from "../../../utils/apiCall"
import { Link } from "react-router-dom"
import { useTheme } from "../../Theme/themeContext"

const TermsAndConditions = () => {
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState('')
    const [pageNotFound, setPageNotFound] = useState(false)
    const { lightTheme, toggleTheme } = useTheme();
    useEffect(() => { getHomeContent() }, [])
    const getHomeContent = async () => {
        setLoading(true)

        const body = {
            link: "tc",
            whitelabel_id: whitelabel_id
        }
        const url = API_ENDPOINTS.GET_TERMS_CONDITIONS
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)

        if (response?.status == 1) {
            setContent(response?.content_data)
            setLoading(false)
        } else if (response?.status == 0) {
            setLoading(false)
            setPageNotFound(true)
        } else {
            setPageNotFound(true)
        }
    }
    return (
        <>
            {!pageNotFound ? <> <BusinessNavbar>
                {!loading ? <div class="container" style={{ paddingTop: "100px" }}>
                    <div className="text-center">
                        <h2>{content?.tc?.title}</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: content?.tc?.content }} className={lightTheme ? "secondary-text-light":"secondary-text"}/>
                </div> : <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "150px", width: "150px", paddingTop: "100px", marginTop: '200px' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}
            </BusinessNavbar>
                <Footer title={"tc"} />
            </> :

                <div class="row justify-content-center h-100 align-items-center" style={{ paddingTop: "150px" }}>
                    <div class="col-md-7">
                        <div class="form-input-content text-center error-page">
                            <h1 class="error-text fw-bold">404</h1>
                            <h4><i class="fa fa-exclamation-triangle text-warning"></i> The page you were looking for is not found!</h4>
                            
                            <div>
                                <Link class="btn btn-success" to={'/'}>Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* <BusinessNavbar>
            <div className="business-container mx-auto">
                <div
                    className="col-xl-12 col-lg-12 wow tpfadeRight"
                    data-wow-duration=".9s"
                    data-wow-delay=".7s"
                    style={{
                        visibility: "visible",
                        animationDuration: "0.9s",
                        animationDelay: "0.7s",
                        animationName: "tpfadeRight"
                    }}
                >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{
                            paddingTop: "10pt",
                            paddingLeft: "50pt",
                            textIndent: "0pt",
                            textAlign: "center"
                        }}
                    >
                        Terms and Conditions for provision of SEPA
                    </h1>
                    <h1 style={{ paddingLeft: "50pt", textIndent: "0pt", textAlign: "center" }}>
                        payment accounts
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l1">
                        <li data-list-text={1}>
                            <h2
                                style={{ paddingLeft: "32pt", textIndent: "-17pt", textAlign: "left" }}
                            >
                                General provisions
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{ paddingLeft: "15pt", textIndent: "0pt", textAlign: "justify" }}
                            >
                                The present Agreement is concluded between Codego and the Client.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l2">
                                <li data-list-text="1.1">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Object of the Agreement:
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The present Agreement determines the main terms and conditions
                                        between the Client and Codego when the Client opens an Account at
                                        Codego by registering in its System and uses other services provided
                                        by Codego. Conditions of separate services provided by Codego are
                                        set under the Supplements to the Agreement, other agreements and
                                        rules which are an integral part of the present Agreement.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        These conditions apply to the Client after the Client becomes
                                        acquainted with the terms of the agreement and starts using the
                                        respective services. In addition to the present Agreement, the
                                        relationship between Codego and the Client related to provision of
                                        Services is also governed by legal acts applicable to the Client,
                                        agreements concluded with the Client, other agreements, rules and
                                        principles of reasonableness, justice, and fairness.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego shall keep under secrecy any data that have become known to
                                        it from its relations with the Client, included data about the
                                        Client, their Account, and payment transactions (hereinafter the
                                        Client’s data subject to banking secrecy) for an unspecified term.
                                        Codego may disclose the Client’s data subject to banking secrecy to
                                        a third partyif it arises from the legislation or from the general
                                        terms and conditions of Codego.
                                    </p>
                                </li>
                                <li data-list-text="1.2">
                                    <h3
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Definitions:
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h4
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Agreement
                                        <span className="p fw-normal text-muted" >
                                            : means the Agreement between the Client and Codego, which
                                            includes the present Terms and conditions for provision of payment
                                            Accounts for business clients, and any other conditions and
                                            documents (supplements, agreements, rules, declarations, etc.).
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Business Day
                                        <span className="p fw-normal text-muted">
                                            : means a day when Codego provides its services, set by Codego.
                                            Codego can set different business days for different services.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Client / Customer:{" "}
                                        <span className="p fw-normal text-muted">
                                            means a legal person or a natural person who has concluded the
                                            Agreement on Codego services.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Client’s Representative
                                        <span className="p fw-normal text-muted">
                                            : means the executive manager of the Client, or the Client’s other
                                            representative, who is authorized to represent the Client in
                                            cooperation with Codego under legal acts and/or activity documents
                                            of the legal person.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Client Identification
                                        <span className="p fw-normal text-muted">
                                            : means verification of the identity of the Client and/or their
                                            beneficial owners under the procedure laid down in the System.
                                        </span>
                                    </h4>
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        <span />
                                    </p>
                                    <table border={0} cellSpacing={0} cellPadding={0} />
                                    <p />
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h4
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Commission Fee
                                        <span className="p fw-normal text-muted">
                                            : means a fee charged by Codego for a Payment Transaction and/or
                                            related services.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Consent
                                        <span className="p fw-normal text-muted">
                                            : means consent of the Payer to perform a Payment Transaction
                                            submitted under the procedure set forth by Article 8 of the
                                            Agreement.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Electronic Money
                                        <span className="p fw-normal text-muted">
                                            : means the Client’s money charged or transferred to and held in a
                                            Codego Account, designated for Payment Transactions via the
                                            System.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Party<span className="p fw-normal text-muted">: means Codego or the Client.</span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Password (Passwords)
                                        <span className="p fw-normal text-muted">
                                            : means any code created by the Client in the system, a code
                                            created by the Client and used for Strong Client Authentication,
                                            or a code provided to the Client for access to the Profile and/or
                                            the Codego Account, or for the initiation, confirmation, and
                                            management of separate services of Codego, and/or initiation,
                                            authorization, implementation, confirmation, and reception of a
                                            Payment Transaction.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payment Transfer
                                        <span className="p fw-normal text-muted">
                                            : means a payment transaction wherein funds are transferred to a
                                            payment account of the Recipient under the initiative of the
                                            Payer.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payment Order
                                        <span className="p fw-normal text-muted">
                                            : means an order (payment transfer) from the Payer or the
                                            Recipient to the Provider of Payment Services to execute a Payment
                                            Transaction.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payment Transaction
                                        <span className="p fw-normal text-muted">
                                            : means a money transfer or cash-in/out operation initiated by the
                                            Payer, in the Payer’s name, or by the Recipient.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payment Service
                                        <span className="p fw-normal text-muted">
                                            : means services, during the provision of which conditions to
                                            deposit to and withdraw cash from the payment account are created,
                                            as well as all transactions related to the management of the
                                            payment account; payment transactions, including transfer of money
                                            held on the payment account opened in Codego of the payment
                                            service provider of the User of payment services, or in another
                                            payment institution; payment transactions when money is given to
                                            the User of payment services under acredit line: payment
                                            transactions using a payment card or a similar instrument and/or
                                            credit transfers, including periodic transfers; issuance and/or
                                            acceptance of payment instruments; money remittances; payment
                                            initiation services; account information services.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payment Instrument
                                        <span className="p fw-normal text-muted">
                                            : means any payment instrument which the System allows to link to
                                            the Codego Account and use it to perform Payment Transfers.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Payer
                                        <span className="p fw-normal text-muted">
                                            : means a natural (private) or legal person, or other organization
                                            or its branch, that has a payment account and allows to execute a
                                            payment order from this account, or,in the absence of a payment
                                            account, submits a Payment Order.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego
                                        <span className="p fw-normal text-muted">
                                            : means Codego company and other legal persons who may be hired
                                            for the purpose of service provision. Codego is a Belgian E-Money
                                            Institution under the prudential Authority of the National Bank of
                                            Belgium, incorporated under the laws of Belgium and registered at
                                            the Belgian Crossroads Bank of enterprises under the number
                                            0506.763.929.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego Account or Account
                                        <span className="p fw-normal text-muted">
                                            : means an account opened in the System in the name of the Client
                                            and used to make payments and other Payment Transactions. An
                                            Account is opened only upon identification of the Client.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego Application (also mentioned below as “System” or “App”)
                                        <span className="p fw-normal text-muted">
                                            : means a mobile/software application for Codego account
                                            management, installed on mobile devices and used for the provision
                                            of Codego services.
                                        </span>
                                    </h4>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h4
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Personal Data
                                        <span className="p fw-normal text-muted">
                                            : means any information related to the natural (private) person
                                            whose identity is known or can be directly or indirectly
                                            determined by using a personal code (national ID number) and one
                                            or more physical, physiological, psychological, economic,
                                            cultural, or social features specific to the individual.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Pricing
                                        <span className="p fw-normal text-muted">
                                            : means prices for Codego services and transactions confirmed by
                                            Codego in accordance with the established regulations.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Profile
                                        <span className="p fw-normal text-muted">
                                            : means the result of registration in the computer system, during
                                            which personal data of the registered person is saved, a login
                                            name is created, and their rights in the system are defined.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Recipient:{" "}
                                        <span className="p fw-normal text-muted">
                                            means a natural or legal person, or another organization or its
                                            branch, specified in the Payment Order as a recipient of the funds
                                            of the Payment transaction.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        SEPA Mandate
                                        <span className="p fw-normal text-muted">
                                            : means the specific form of Mandate required to be used in order
                                            to effect Credit or Direct Debits in SEPA.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Services
                                        <span className="p fw-normal text-muted">
                                            : means the service of issuance and redemption of electronic money
                                            and other services provided by Codego included providing IBAN
                                            accounts, the possibility of making SEPA and direct debit
                                            transfers ; a payment service provided by Codego, also any other
                                            service provided by Codego.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Strong Client Authentication
                                        <span className="p fw-normal text-muted">
                                            : means the procedure of verification of the identity of a natural
                                            or legal person based on the use of two or more elements
                                            categorized as knowledge (e.g. static password, code, personal
                                            identification number), possession (e.g. token, smart card, mobile
                                            phone) and inherence (e.g. biometric characteristics, such as
                                            afingerprint). This procedure is applied when the Client is
                                            logging in to their payment account online or through other means
                                            of remote access, initiates an electronic payment transaction and,
                                            through the means of remote access, carries out any action that
                                            might be related to the risk of fraud in carrying out a payment or
                                            any other types of misuse.
                                        </span>
                                    </h4>
                                    <h4
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Unique Identifier
                                        <span className="p fw-normal text-muted">
                                            : means a combination of letters, numbers, or symbols which
                                            Codego, as a provider of payment services, provides to the User of
                                            payments services,and which is used to identify the User of
                                            payment services participating in the Payment Transaction, and/or
                                            the account of the User used in the Payment Transaction.
                                        </span>
                                    </h4>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="1.3">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Registration in the System:
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        To start using Codego Services, the Client has to register in the
                                        System. The use ofthe Codego Application is described in the Terms
                                        of Use available in the system. Codego has the right to refuse to
                                        register the new Client without indicating the reasons, however,
                                        Codego assures that the refusal to register will always be based on
                                        significant reasons which Codego does not have to or does not have
                                        the right to reveal.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Account for the Client can be opened by the Client’s
                                        Representative. By registering the Client in the System, the
                                        Client’s Representative confirms that they are duly elected or
                                        appointed to represent the Client, also that the legal entity
                                        represented by them is properly established and operates lawfully.
                                        The Client’s Representative must provide the documents specified in
                                        the System in order to be duly verified under the procedures laid
                                        down in the System.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If the onboarding procedure is successfully completed and Codego is
                                        satisfied with the identification and verification of the identity
                                        of the Customer concerned, the Bank shall
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        confirm the acceptance of the Customer, Codego shall confirm the
                                        Customer's acceptance and the contractual relationship between the
                                        Customer and Codego shall take effect in accordance with these
                                        General Terms and Conditions.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Agreement comes into force after the client’s Representative has
                                        registered theClient in the System, learned the terms and conditions
                                        of the present Agreement and every related document, and
                                        electronically expressed their consent to comply with them. The
                                        Agreement is valid for an unlimited period of time.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        By registering in the System, the Client confirms that they agree to
                                        the terms of the Agreement and undertakes to observe them. The
                                        Client confirms that they have provided the correct data when
                                        registering in the System and, if there is a need to change or add
                                        data, the Client will submit correct data only. The Client shall
                                        bear any losses that may occur due to the submission of invalid
                                        data.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In order for Codego to start or continue the provision of Services,
                                        the Client and/orthe Client’s Representative shall confirm the
                                        profile, the provision of a new Service or a part of a Service and
                                        perform the Client identification procedure under the
                                        circumstancesand procedures set out in the Agreement or in the
                                        System. The Client identification procedure, confirmation of the
                                        Profile, and provision of new Services is performed in order to
                                        ensure the protection of the interests of the Client and Codego.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego has the right to demand data and/or documents that would help
                                        Codego identify the Client and/or receive significant information
                                        necessary for proper provision ofCodego Services to the Client.
                                        Specific data and/or documents to be submitted shall be indicated in
                                        the message to the Client about the necessity to perform Client
                                        identification or other verification procedures.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "107%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        For the purpose of performing Client identification, Codego has the
                                        right demand the Client to perform the following actions:
                                    </p>
                                    <ul id="l3">
                                        <li data-list-text="-">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "51pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "109%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Provide originals of the documents required by Codego and/or
                                                their copies of documents approved by a notary, or another
                                                person authorized by the state.
                                            </p>
                                        </li>
                                        <li data-list-text="-">
                                            <p
                                                style={{
                                                    paddingLeft: "51pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Codego, in performing the obligation to identify the
                                                beneficiary, has the right to require the Client to submit a
                                                valid list of participants of their legal entity. When
                                                submitting this list, the Client must confirm that it is
                                                relevant and accurate and that the listed persons control the
                                                shares of the legal person in their own name and not in the name
                                                of third parties. If the shares of the legal entity are
                                                controlledin the name of third persons, the Client must indicate
                                                these circumstances in addition, also specifying the third
                                                parties who are managing the shares. Codegohas the right to
                                                refuse to provide Services if it turns out that it is not
                                                possible to identify the beneficiaries of the legal entity.
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Parties agree that the Client can confirm -or sign- documents
                                        (e.g., agreements, consents, etc.) by electronic means.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego has the right to demand additional information and/or
                                        documents related to the Client or transactions executed by them and
                                        has the right to suspend a transaction ofthe Client until the Client
                                        provides additional information and/or documents related to the
                                        suspended transaction. Codego has also the right to request the
                                        Client to fill in and periodically update the Client’s
                                        questionnaire. If the Client does not provide additional information
                                        and/or documents within a reasonable time period set by Codego,
                                        Codego has the right to suspend the provision of all or a part of
                                        the Services to the Client.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={2}>
                            <h2
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "32pt",
                                    textIndent: "-17pt",
                                    textAlign: "left"
                                }}
                            >
                                How the Payment Account works
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                In the event of acceptance of the opening of the Payment Account, an
                                email of confirmation will be sent by Codego to the Client. The payment
                                account number (IBANnumber) opened in the name of the Client is
                                available in its Personal Area.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                The Client can then send funds to their Payment Account, via SEPA
                                transfer, by a first incoming transfer from an account opened in their
                                name with a payment service provider located in the European Union, the
                                European Area or a third countries imposing equivalent obligations in
                                the fight against money laundering and the financing of terrorism.
                                Notwithstanding the foregoing, the Client may not make any Payment
                                Transactions until Codego has proceeded activation of all Services.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                The Client can manage the Account via the Internet by logging in to the
                                personal Profile of the Client with the login and password and carrying
                                out additional authentication (Strong Customer Authentication).
                            </p>
                            <ol id="l4">
                                <li data-list-text="2.1">
                                    <h3
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Designation of Users
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The opening of the Account is made through the Owner who has the
                                        rights to represent and engage the Client. The Owner may be a
                                        corporate officer or a natural person other than the corporate
                                        officer expressly mandated by the Client. In the event of loss by
                                        the Owner of his/her rights to the Account (for example, change of
                                        the corporate officer or revocation of the Power of attorney of the
                                        authorized person), the Client undertakes to inform Codego without
                                        delay. In the absence of notification or in the event of late
                                        notification, the liability of CODEGO cannot be engaged.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Moreover, the Client may give Power of attorney to Administrators or
                                        Members authorized to use the Services on their behalf and for their
                                        account, and under their entire responsibility. The Power of
                                        attorney form is available in the Personal Area of the Account’s
                                        Owner or Administrator. The power of attorney will only take effect
                                        upon receipt by Codego of the duly completed form and the required
                                        supporting documents.The power of attorney ceases automatically upon
                                        the death of the Owner or the Administrator who has issued it. The
                                        power of attorney may be revoked by the Client at any time by
                                        informing Codego through their Personal Area without undue delay. If
                                        thenotification is not made or is made late, the Power of attorney
                                        remains valid and CODEGO cannot be held liable. The Client expressly
                                        discloses the obligation of professional secrecy relating to the
                                        Payment Account data in respect of Users.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client determines for each User the scope of the rights he/she
                                        has on the Payment Account. Each User is assigned Personalized
                                        Security Data of his/her own, in order to access his/her Personal
                                        Area. The Personal Area of each User is personalized according to
                                        the rights granted to him/her by the Client. The different Users
                                        profiles are: Owner, Administrator, and Member.
                                    </p>
                                </li>
                                <li data-list-text="2.2">
                                    <h3
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Personalized security data
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client must take all reasonable steps to maintain the
                                        confidentiality and security of its Personalized Security Data. It
                                        also undertakes to make users aware of the preservation of the
                                        confidentiality and security of their own personalized security
                                        data.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client (and each User) undertakes not to communicate their
                                        Personalized Security Data to third parties. Exceptionally, the
                                        Client may communicate them to an Access
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Service Provider for the purpose of providing the account
                                        information service or the payment initiation service. In this case,
                                        and having expressly consented to access their Account, the Client
                                        must ensure that the said Provider is approved or registered for the
                                        aforementioned services, and that they enter their Personalized
                                        Security Data in a secure environment.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego reserves the right to refuse access to the Payment Account to
                                        such a Provider if it suspects that access to the Account is not
                                        authorized or fraudulent. Codego will inform the Client by any means
                                        of the refusal of access to the Payment Account and the reasons for
                                        such refusal, unless this information is not available for
                                        objectively justified security reasons or under a relevant provision
                                        of national or European Union regulation.
                                    </p>
                                </li>
                                <li data-list-text="2.3">
                                    <h3
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Statements
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client is informed by Codego of any provision of information on
                                        a durable medium within the meaning of the law and case law. Codego
                                        provides the Client with a statement of the Payment Transactions on
                                        their Account. This statement is available in their Personal Area.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client undertakes to check the contents of the Statement of
                                        Operations and to keep it for a minimum of five (5) years. The
                                        statement is a legal record of all Payment Transactions made on the
                                        Payment Account.
                                    </p>
                                </li>
                                <li data-list-text="2.4">
                                    <h3
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Balance of the payment account
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        As the Client's Payment Account balance cannot be in any way
                                        negative, the Client undertakes to maintain a sufficient balance on
                                        their Payment Account to ensure the execution of the Payment
                                        Transactions. In the case of an insufficient balance, Codego shall
                                        reject the Transactions concerned.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Exceptionally, and without any obligation of payment facility,
                                        Codego may be requiredto pay one or more Transactions, the amount of
                                        which would be greater than the balanceof the Client's Payment
                                        Account (in particular in the case of an Operation by card without
                                        prior authorization, or issuance of unpaid card or a direct debit).
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In this situation, the Client undertakes to send funds to their
                                        Payment Account without delay in order to restore a positive or zero
                                        balance. In case of non-compliance with these obligations, Codego
                                        reserves the right to suspend or close the Payment Account and touse
                                        all means to recover the amounts due.
                                    </p>
                                </li>
                                <li data-list-text="2.5">
                                    <h3
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Inactive account
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client's Payment Account is considered inactive when, after a
                                        period of twelve (12) months, it has not been the subject of any
                                        transaction (excluding management fees) on the initiative of the
                                        Client (or any User) and that the latter has not made any
                                        representations to Codego in any form whatsoever.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        When the Account is considered inactive, Codego informs the Client
                                        by any means. In the absence of a response from the Client or any
                                        new transaction on the Account and in the case where the balance is
                                        positive, the Account will be closed at the end of a period of ten
                                        (10) years from the last transaction on the account. The Client will
                                        be informed by any means six (6) months before the effective closing
                                        of the Account.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The balance will be deposited with the authorized Institution and
                                        the sums may be claimed by the Client or his beneficiaries for
                                        twenty (20) years from their deposit. Codego may debit an inactive
                                        account management fee each year, to the extent permitted by law.
                                    </p>
                                </li>
                                <li data-list-text="2.6">
                                    <h3
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Anti Money laundering and terrorist financing
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        As a Payment Service Provider, Codego is subject to the legal and
                                        regulatory provisions relating to the fight against money laundering
                                        and the financing of terrorism. For this purpose, Codego must carry
                                        out all the necessary procedures relating to the identification of
                                        the Client and, when applicable, the ultimate beneficial owner, as
                                        well as to the verification of the identity of the latter.
                                        Throughout the duration of the Contract, the Client undertakes to
                                        keep Codego informed about any changes without delay concerning, in
                                        particular, their activity, the identification of their corporate
                                        officers and beneficial owners, including a change of control.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In addition, Codego must inquire about the origin of the Payment
                                        Transactions, their purpose and the destination of the funds. From
                                        an operational point of view, Codego is required to set up a system
                                        for monitoring and detecting atypical payment transactions.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client undertakes to comply with obligations to combat money
                                        laundering and terrorist financing by providing information to
                                        Codego about any unusual Payment Transactions detected by Codego.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego reserves the right to request any other document or
                                        additional information if deemed necessary to meet its vigilance
                                        obligations in the sense of the fight against money laundering and
                                        the financing of terrorism. As such, Codego could postpone the
                                        opening of the Payment Account or temporarily block and even close
                                        it in case of persistent suspicion.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client ensures that:
                                    </p>
                                    <ul id="l5">
                                        <li data-list-text="-">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "51pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "109%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Incoming funds in their Codego Account are not obtained as a
                                                result of criminal activity
                                            </p>
                                        </li>
                                        <li data-list-text="-">
                                            <p
                                                style={{
                                                    paddingLeft: "51pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                The Client will not use services provided by Codego for any
                                                illegal purposes, including actions and transactions in order to
                                                legalize funds derived from criminal or other illegal activities
                                            </p>
                                        </li>
                                    </ul>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={3}>
                            <h2
                                style={{ paddingLeft: "32pt", textIndent: "-17pt", textAlign: "left" }}
                            >
                                Execution of payment transactions
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l6">
                                <li data-list-text="3.1">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Payment transaction
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        A Payment Transaction is independent of the underlying civil or
                                        commercial obligation between the Client and the Payment Recipient.
                                        Codego therefore remains foreign to any civil or commercial dispute
                                        that may arise between the Client and the Beneficiary.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        A Payment Transaction may be initiated by the Client who gives a
                                        Payment Order (transfer) directly, by the Client who gives a Payment
                                        Order through the Beneficiary (card) or by the Beneficiary (direct
                                        debit).
                                    </p>
                                </li>
                                <li data-list-text="3.2">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            lineHeight: "12pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Security of payment instruments
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        <a href="mailto:support@Codego.com" className="a" target="_blank">
                                            The Client will take reasonable steps to maintain the security of
                                            their Custom Security Data. Upon knowledge of loss, theft,
                                            misappropriation or any unauthorized use of a payment instrument
                                            or related data, the Client shall promptly inform Codego for the
                                            purpose of blocking (or opposition) of the instrument, by email:
                                            support@Codego.com
                                        </a>
                                        . The Client can also claim a direct opposition from their
                                        PersonalArea.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        This request must be confirmed by letter with acknowledgment of
                                        receipt sent to the following address: Codego SA – Cantersteen 47,
                                        1000 Brussels. Codego reserves the right to subsequently request a
                                        receipt or a copy of the complaint following the theft or fraudulent
                                        use of its Account. The Client undertakes to respond to Codego's
                                        requestas soon as possible.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego executes the request for opposition as soon as it receives
                                        it. The event will be recorded and timestamped. An opposition number
                                        with timestamp will be communicated to the Client. A written
                                        confirmation of this opposition will be sent to the concerned Client
                                        by email.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In case of blocking (or opposition), Codego provides the Client, at
                                        their request and for eighteen (18) months from the blocking (or
                                        opposition), the elements allowing them to prove that they have
                                        successfully blocked (or opposed).
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Any misrepresentation by the Client or Users may result in legal
                                        action.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="3.3">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Strong Client Authentication
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In accordance with the law, Codego applies Strong Client
                                        Authentication when it:
                                    </p>
                                    <ul id="l7">
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "25pt",
                                                    textIndent: "-9pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                accesses the Client’s Online Payment Account;
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "25pt",
                                                    textIndent: "-9pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                initiates an Electronic Payment Transaction;
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "109%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                executes an Operation through a means of remote communication,
                                                which may involve a risk of fraud in payment or other fraudulent
                                                use.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Strong Authentication is performed by the input of a 2-factor
                                                authentication code received by SMS on the phone number
                                                associated with the User, in the dedicated field of the
                                                Application.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={4}>
                            <h2
                                style={{ paddingLeft: "32pt", textIndent: "-17pt", textAlign: "left" }}
                            >
                                Execution of payment orders by transfer
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l8">
                                <li data-list-text="4.1">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        General description
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client may issue, via a User who has the necessary rights (Owner
                                        or Administrator) a payment Order by transfer from their Payment
                                        Account to an account opened in the books of another payment service
                                        provider.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client may initiate Transfer Orders in Euros only. The list of
                                        currencies covered is indicated in the Personal Area of the
                                        authorized User.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        To initiate a Transfer Order, the User who has the necessary rights
                                        connects to his/her Personal Area using his/her Identification Data,
                                        entering:
                                    </p>
                                    <ul id="l9">
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                The amount of the Payment Transaction (the User must ensure that
                                                the Account has a balance sufficient to cover the amount of the
                                                Payment Transaction and any associated costs);
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "5pt",
                                                    paddingLeft: "25pt",
                                                    textIndent: "-9pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                The identity of the Beneficiary of the transfer as well as
                                                his/her bank details (IBAN);
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "109%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                The execution date (in the absence of indication of date, the
                                                Transfer Order occurs immediately);
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "25pt",
                                                    textIndent: "-9pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                The reason for payment;
                                            </p>
                                        </li>
                                        <li data-list-text="●">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "25pt",
                                                    textIndent: "-9pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Currency.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                The User is invited to check all of this information before
                                                validating the Transfer Order.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                In case the Payer indicates incorrect data of the Recipient, and
                                                the Payment Order is executed according to the data provided by
                                                the Payer, it shall be considered that Codego has fulfilled its
                                                obligations properly and shall not repay the transferred
                                                amountto the Payer. Codego commits to take all necessary actions
                                                to track the payment transaction and will seek to return the
                                                funds of the payment transaction, however, in the event of
                                                failure to do so, the Payer shall directly contact the person
                                                who has received thetransfer, on the issue of returning the
                                                money.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                The consent of the User to the Transfer Order is collected
                                                according to the procedure indicated in the Personal Area. The
                                                User must follow any strong authentication procedure requested
                                                by Codego. The Transfer Order is irrevocable once it has been
                                                definitively validated by the User from his/her Personal Area.
                                                Codego will not accept any request for cancellation of a
                                                transfer beyond its date of irrevocability.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Transfer Orders are time stamped and kept for the applicable
                                                legal period. When the consent is given through a Service
                                                Provider providing a payment initiation service, the form of
                                                this consent is determined by the Client and the said Provider,
                                                under the conditions agreed between them. Codego is not a party
                                                to these conditions and does not have to verify the Client's
                                                consent.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "15pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                When the Transfer Order is initiated, at the request of the
                                                Client, by a service provider providing a payment initiation
                                                service, the Client may not revoke the Order after granting
                                                consent.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li data-list-text="4.2">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        transfers denominated in Euros
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Transfer Order must comply with SEPA rules set forth in the
                                        “SEPA Credit Transfer Rulebook.” The User has the option of issuing
                                        instant or standard Transfer Orders, one-time, or recurring Transfer
                                        Orders.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        For instant Transfer Orders, the Transfer Order is deemed received
                                        by Codego once theUser has definitively given his/her consent to the
                                        Order, according to the procedure indicated in the Personal Area
                                        ("Date of receipt"). It is expressly agreed that the Orders for
                                        Instant Transfers will be executed by Codego not later than the end
                                        of the Business Day following the Date of receipt of the Order by
                                        Codego. If the Date of receipt is nota Business Day, the Payment
                                        Order is determined to be received on the next Business Day. The
                                        Parties also agree that any Payment Order validated on a Business
                                        Day after 16:45 shall be received on the next Business Day.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        With respect to standard Transfer Orders, they will be executed at
                                        the latest at the end of the day indicated by the Client. If it is
                                        not a Business Day, Codego will execute the Transfer Order on the
                                        next Business Day.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="4.3">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Refusal of execution
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego may refuse to execute any incomplete or incorrect Transfer
                                        Order. The Client will then be asked to re-issue the Order to edit
                                        missing or incomplete information.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In addition, Codego may suspend a Transfer Order in the event of
                                        serious doubt of fraudulent use of the Account, unauthorized use of
                                        the Account, breach of security of the Account, suspicion of money
                                        laundering / financing of terrorism, or in the event of an
                                        assets-freeze order issued by an administrative authority.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In case of refusal of execution or blocking of a Transfer Order,
                                        Codego will inform the Client by any means as soon as possible, and
                                        at the latest by the end of the first Business Day following the
                                        Date of receipt. If possible, Codego will indicate the reasons for
                                        the refusal or blocking to the Client, unless prohibited by a
                                        relevant provisionof national or European Union law. The Client is
                                        informed that such notification may be subject to the charges
                                        indicated in the Pricing if the refusal is objectively motivated.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="4.4">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Contestations concerning Payment orders by transfer
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If the Client wishes to contest an allegedly unauthorized or
                                        incorrectly executed Transfer, they must contact Codego's Client
                                        service by phone call or email as soon as possible after becoming
                                        aware of the discrepancy and no later than four (4) weeks following
                                        the registration of the Payment Transaction in the Account.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Unless Codego has reasonable grounds to suspect fraud by the Client
                                        or grossnegligence on the part of the Client, Codego shall reimburse
                                        the Client for the amount of the payment Transaction immediately
                                        after receiving the contestation, and in any event not later than
                                        the end of the following Business Day. Codego restores the Account
                                        to the state in which it would have been if the Unauthorized Payment
                                        Transactionhad not taken place. Codego reimburses the Client under
                                        the same conditions when the Payment Transaction was initiated by a
                                        payment initiation service provider.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Fees and Pricing Conditions may be levied in the event of an
                                        unjustified contestation of a Payment Transaction. Codego cannot be
                                        held liable when the incorrect execution of the payment Transaction
                                        is the result of an error by the Client on the Unique Beneficiary
                                        Identifier (IBAN). Codego will endeavor to recover funds committed
                                        to the payment Transaction.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If Codego is unable to recover funds, the Client may request Codego
                                        to provide anyrelevant information it has in order to document its
                                        legal recourse to recover the funds.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="4.5">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Receiving transfers
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Under the terms hereof, the Client expressly mandates Codego to
                                        receive SEPA Transfer Orders in Euros from an account opened in the
                                        books of a payment service provider located in the SEPA zone in
                                        their name and on their behalf.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego credits the Client's Payment Account not later than the end
                                        of the Business Day on which their own account has been credited
                                        with the funds. As soon as the transaction is credited to the
                                        Client's Payment Account, Codego shall make a summaryof the
                                        transaction including the following information available in the
                                        Personal Area: amount, date and time, Payment Transaction number,
                                        name of the Payer, debited account, and reason of the Transaction
                                        (if applicable).
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client having noticed that money has been credited to or
                                        deducted from their Codego
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Account by mistake or in other ways that have no legal basis, is
                                        obliged to notify Codego about it. The Client has no right to
                                        dispose of money that does not belong to them. In such cases, Codego
                                        has the right, and the Client gives an irrevocable consent to deduct
                                        the money from their Codego Account without the Client’s order. If
                                        the amount of money in the Codego client’s Account is insufficient
                                        to debit money credited to or deducted from their Codego Account to
                                        their other accounts by mistake, the Client unconditionally commits
                                        to repay Codego the money credited to or deducted from the Codego
                                        Account to their other accounts by mistake in3 (three) business days
                                        from the receipt of such request from Codego.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={5}>
                            <h2
                                style={{ paddingLeft: "32pt", textIndent: "-17pt", textAlign: "left" }}
                            >
                                Direct debits
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l10">
                                <li data-list-text="5.1">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        SEPA core and direct debits
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client has the option of paying by SEPA direct debit for persons
                                        with whom they have a business relationship (the "Creditors"). For
                                        the purpose of this article, the term"Maturity Date" means the date
                                        of interbank settlement, i.e. the date of debiting the Client's
                                        Account.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="5.2">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Direct debit mandate (“Mandate”)
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client who accepts the SEPA Direct Debit as a method of payment
                                        must complete the Mandate delivered by their Creditor and return it
                                        to them accompanied by a Bank Statement of Identity on which his BIC
                                        and IBAN appear. The physical person signing the SEPA Direct Debit
                                        Mandate must be a person authorized by the Client for this
                                        transaction. The Client undertakes to inform the Establishment of
                                        the signature of any Collection Order.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        By signing the Direct Debit Mandate, the Client expressly waives the
                                        right to reimbursement of authorized and correctly executed
                                        transactions.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client may at any time revoke the Collection Order from their
                                        Creditor. In this case, they undertake to inform Codego immediately.
                                        Codego cannot be held responsible for a poorly executed operation
                                        due to a lack of information from the Client (for example:bank
                                        account number missing or bank details incomplete).
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client undertakes to inform Codego immediately of any change to
                                        the Terms of Reference. Codego cannot be held responsible for a
                                        poorly executed operation due to alack of information from the
                                        Client.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client may also revoke the Mandate at any time within their
                                        Personal Area or by contacting Codego. For this purpose, they shall
                                        communicate the unique Reference of the Mandate to Codego. The
                                        revocation must be requested by the Client at the latest before the
                                        end of the Business Day preceding the Expiration Date of the next
                                        withdrawal operation provided for by the Mandate.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The revocation entails the definitive withdrawal of the Client's
                                        consent to the execution of the Mandate. Codego will refuse all the
                                        Orders of Samples presented after therevocation of the Mandate by
                                        the Client.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        A Money Order for which no SEPA Direct Debit Order has been
                                        submitted for a period of thirty-six (36) months becomes null and
                                        void. In this case, the Client must enter and validate a new
                                        mandate.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="5.3">
                                    <h3
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Direct debit orders
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client is informed that their Creditor is required to provide
                                        them with advance notice of at least fourteen (14) calendar days
                                        before the SEPA Direct Debit Due Date, unless there is a specific
                                        Contract between the Client and the Creditor in the Mandate.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Upon receipt of this notification, the Client has the opportunity to
                                        verify compliance with their relationship with the Creditor. The
                                        Client must ensure that they has sufficient funds in their Account
                                        on the Due Date.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In the event of disagreement, the Client is invited to immediately
                                        address their Creditor so that the latter suspends the transmission
                                        of the Collection Order or issue an instruction for the revocation
                                        of the original Direct Debit Order.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego receives the Direct Debit Orders transmitted by the
                                        Creditor's payment service provider no later than the day before the
                                        Due Date. For a first recurring charge or for an one-off charge,
                                        Codego will verify the existence of the Client's consent and the
                                        validityof the Mandate. In case of inconsistency or incomplete data,
                                        Codego may reject the relevant Direct Debit Operation.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        For the following recurring Direct Debits, Codego verifies the
                                        consistency of the mandate data with the data already recorded and
                                        the data of the Transactions. In case of inconsistency, Codego will
                                        contact the Client.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego debits the Client's Payment Account of the amount of the
                                        Transaction when no event is against it and provided that the
                                        Payment Account has a sufficient provision. The Client will receive
                                        a notification in their Personal Area to inform them of the amount
                                        debited from their Account.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="5.4">
                                    <h3
                                        style={{
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        direct debit orders dispute
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        It is specified that the Client has no right to reimbursement if the
                                        mandate does not indicate the exact amount of the debit transaction
                                        and the amount of the transaction exceeds the amount to which the
                                        Client could reasonably expect.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Client may request the refund of an unauthorized deduction
                                        within thirteen (13) months from the date of debiting their account,
                                        under penalty of foreclosure. This period shall be reduced to
                                        seventy (70) days if the Beneficiary's payment service provider is
                                        located in a State that is not a member of the European Union or the
                                        European Economic Area.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={6}>
                            <h2
                                style={{ paddingLeft: "32pt", textIndent: "-17pt", textAlign: "left" }}
                            >
                                force majeure
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                Neither party will be liable for any delays in processing or other
                                nonperformance caused by telecommunications, utility, failures, or
                                equipment failures, labor strife, riots, war or terrorists attacks,
                                pandemic context, nonperformance of our vendors or suppliers, fires or
                                acts of nature, or any other event over which the respective party has
                                no reasonable control.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "left"
                                }}
                            >
                                However, nothing in this section will affect or excuse your liabilities
                                or your obligation to pay fees, fines, disputes, refunds, reversals or
                                returns under this agreement.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={7}>
                            <h2
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "32pt",
                                    textIndent: "-17pt",
                                    textAlign: "justify"
                                }}
                            >
                                Warranties
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                By accepting the terms of this Agreement, you represent and warrant
                                that: (a) you are eligible to register and use the Services and have the
                                authority to execute and perform the obligations required by this
                                Agreement; (b) any information you provide us about your business,
                                products, or services is accurate and complete; (c) any Charges
                                representa Transaction for permitted products, services, or donations,
                                and any related information accurately describes the Transaction; (d)
                                you will fulfil all of your obligations to Customers and will resolve
                                all Disputes with them; (e) you will comply with all Laws applicable to
                                your business and use of the Services; (f) your employees, contractors
                                and agents will at all times act consistently with the terms of this
                                Agreement; (g) you will notuse Payment Processing Services for personal,
                                family or household purposes, for peer-to-peer money transmission, or
                                (except in the normal course of business) intercompany Transactions; and
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "justify"
                                }}
                            >
                                (h) you will not use the Services, directly or indirectly, for any
                                fraudulent or illegal undertaking, or in any manner that interferes with
                                the normaloperation of the Services.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                You affirm that Codego does not control the products or services that
                                you offer or sell or that your customers purchase using the payment
                                processing services. You understand that we can’t guarantee and we
                                disclaim any knowledge, that your customers possess the authority to
                                make, or will complete, any transaction.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                Codego disclaims any knowledge of, and do not guarantee:
                            </p>
                            <ul id="l11">
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        The accuracy, reliability, or correctness of any data provided
                                        through the services;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        That the services will meet your specific business needs or
                                        requirements;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        That the services will be available at any particular time or
                                        location, or will function in an uninterrupted manner or be secure;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        That Codego will correct any defects or errors in the service, API,
                                        documentations, or data and;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "12pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        That the services are free or viruses or other harmful code.
                                    </p>
                                </li>
                            </ul>
                            <p
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Use of data you access through the services is done at your own risk.
                                You are solely responsible for any damage to your property, loss of
                                data, or any other loss that results from such access. You understand
                                that Codego make no guarantees to you regarding transaction processing
                                times or payout schedules.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Nothing in this agreement operates to exclude, restrict or modify the
                                application of any implied condition, warranty or guarantee, or the
                                exercise of any right or remedy, or the imposition of any liability
                                under law where to do so would contravene that law or cause any term of
                                this agreement to be void.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={8}>
                            <h2
                                style={{
                                    paddingLeft: "32pt",
                                    textIndent: "-17pt",
                                    textAlign: "justify"
                                }}
                            >
                                Liability
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                className="s1"
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Under no circumstances will Codego be responsible or liable to you for
                                any indirect, punitive, incidental, special, consequential, or exemplary
                                damages resulting from your use or inability to use the Services or for
                                the unavailability of the Services, for lost profits, personal injury,
                                or property damage, or for any other damages arising out of, in
                                connection with, or relating to this Agreement or your use of the
                                Services, even if such damages are foreseeable, and whether or not you
                                or Codego have been advised of thepossibility of such damages.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                className="s1"
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Codego is not liable, and deny responsibility for, any damages, harm, or
                                losses to you arising from or relating to hacking, tampering, or other
                                unauthorized access or use of the Services, your Codego Account, or
                                Data, or your failure to use or implement anti-fraud measures, Security
                                Controls, or any other data security measure. Codego further deny
                                responsibility for all liability and damages to you or others caused by
                            </p>
                            <ol id="l12">
                                <li data-list-text="(a)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        your access or use of the Services inconsistent with the
                                        Documentation;
                                    </p>
                                </li>
                                <li data-list-text="(b)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        any unauthorized access of servers, infrastructure, or Data used in
                                        connection with the Services;
                                    </p>
                                </li>
                                <li data-list-text="(c)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "33pt",
                                            textIndent: "-17pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        interruptions to or cessation of the Services;
                                    </p>
                                </li>
                                <li data-list-text="(d)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        any bugs, viruses, or other harmful code that may be transmitted to
                                        or through the Services;
                                    </p>
                                </li>
                                <li data-list-text="(e)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        any errors, inaccuracies, omissions, or losses in or to any Data
                                        provided to us;
                                    </p>
                                </li>
                                <li data-list-text="(f)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "32pt",
                                            textIndent: "-16pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        third-party content provided by you; or
                                    </p>
                                </li>
                                <li data-list-text="(g)">
                                    <p
                                        className="s1"
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        the defamatory, offensive, or illegal conduct of others.
                                    </p>
                                </li>
                            </ol>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={9}>
                            <h2
                                style={{ paddingLeft: "69pt", textIndent: "-54pt", textAlign: "left" }}
                            >
                                Dispute resolution
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                className="s1"
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                This Agreement and the rights of the parties hereunder shall be governed
                                and construed in accordance with the laws of Belgium, exclusive of
                                conflict or choice of law rules. Nothing in this section will preclude
                                the parties from seeking provisional remedies in aid of arbitration from
                                a court of appropriate jurisdiction.
                            </p>
                            <p
                                className="s1"
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Each party hereby irrevocably and unconditionally consents to service of
                                process through personal service at their corporate headquarters,
                                registered address, or primary address (for individuals or sole
                                proprietors). Nothing in this Agreement will affect the right of any
                                party to serve process in any other manner permitted by Law.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={10}>
                            <h2
                                style={{ paddingLeft: "46pt", textIndent: "-30pt", textAlign: "left" }}
                            >
                                Entire Agreement
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                This Agreement and all policies and procedures that are incorporated by
                                reference constitute the entire agreement between you and Codego for
                                provision and use of the Services. Except where expressly stated
                                otherwise in a writing executed between you and Codego, this Agreement
                                will prevail over any conflicting policy or agreement for the provision
                                or use of the Services. This Agreement sets forth your exclusive
                                remedies with respect to the Services. If any provision or portion of
                                this Agreement is held to be invalid or unenforceable under Law, then it
                                will be reformed and interpreted to accomplish the objectives of such
                                provision to the greatest extent possible, and all remaining provisions
                                will continue in full force and effect.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={11}>
                            <h2
                                style={{ paddingLeft: "46pt", textIndent: "-30pt", textAlign: "left" }}
                            >
                                Duration, Suspension and Termination
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{ paddingLeft: "15pt", textIndent: "0pt", textAlign: "justify" }}
                            >
                                The Agreement is of unlimited duration.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                Codego, at its own discretion, and taking into consideration a specific
                                situation, giving preference to execution of legal acts applied to the
                                activity of Codego, and interests ofthe Client, has the right to
                                unilaterally and without a prior notice apply one or several of the
                                following measures:
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ul id="l13">
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "5pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        To suspend execution of one or several Payment transfers
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        To suspend the provision of all or part of Services to the Client
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        To detain the client’s funds that are matter of dispute
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        To block the Account (i.e. fully or partially suspend Payment
                                        Transactions on the Account)
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "12pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        To refuse to provide Services
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "left"
                                        }}
                                    >
                                        To return arrested funds from the Account of the Client to the
                                        primary sender funds
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The aforementioned measures may be applied only in the following
                                        exceptional cases :
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If the Client essentially violates the Agreement or its Supplements
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If the activities of the client using a Codego Account have the
                                        potential to harm Codego’s business reputation
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If the Client fails to complete the necessary identification
                                        procedures, or submit information required by Codego, or the Client
                                        provides information that doesnot conform to the requirements
                                        stipulated by legislation or Codego
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        If Codego receives substantiated information about the Client’s
                                        liquidation or bankruptcy case
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            lineHeight: "12pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In cases specified by legislation
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingLeft: "51pt",
                                            textIndent: "-18pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        In other cases stated in the Agreement or its Supplements.
                                    </p>
                                </li>
                            </ul>
                            <p
                                style={{
                                    paddingTop: "9pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                You may terminate the Agreement and close your account by giving thirty
                                (30) days written notice (by any medium). The closure commences on the
                                day a party issues the written notice. Once the closure has come into
                                effect the Client can no longer give instructions concerning accounts,
                                and Codego may reject any such instructions. Once the closure is
                                effective Codego stops all related services. Any funds remaining after
                                the closure of the online account will be transferred to any other
                                external business bank account as indicated in the closure notice.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Codego has the right to terminate the Agreement giving at least two (2)
                                months’ notice to the Client by post or email, at the last communication
                                address declared by the Client or by another durable medium.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Without prejudice to all other available rights of Codego under the
                                applicable laws or this Agreement, and unless otherwise provided in the
                                Specific Terms and Conditions, the Agreement may be terminated by Codego
                                with immediate effect and without compensation fee if one of the
                                following events occurs:
                            </p>
                            <ul id="l14">
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "23pt",
                                            textIndent: "-8pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        the Client acts in contravention of generally accepted business
                                        practice;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "107%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        the Client violates the legal regulations (inter alia international
                                        regulations against money laundering) or violates the interests of
                                        Codego;
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        the Client fails to respect this Agreement, for example, by not
                                        paying the services fees in due time (during two or more consecutive
                                        months)
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        in case of any insolvency event concerning the Client or seizure
                                        procedures against the Client
                                    </p>
                                </li>
                                <li data-list-text="-">
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        in case Codego or the client is denied or withdrawn any license,
                                        registration or approval by any Competent Authority or the Payment
                                        Scheme necessary to perform the Services.
                                    </p>
                                </li>
                            </ul>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={12}>
                            <h2
                                style={{ paddingLeft: "46pt", textIndent: "-30pt", textAlign: "left" }}
                            >
                                Confidentiality and Data Protection
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                The Parties undertake to keep the technical and commercial information
                                of each other secret, except for publicly available information which
                                has become known to them while executing the present Agreement, and not
                                transfer it to third parties without written consent from the other
                                Party or its legal representatives.
                            </p>
                            <p
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                The Client agrees for Codego to manage their Personal Data with an aim
                                to provide services to the Client and execute other responsibilities
                                under the present Agreement. The Parties guarantee the security of
                                Personal Data received while executing the present Agreement. The
                                above-mentioned Personal Data cannot be disclosed to third Parties
                                without consent from the subject of this data, except for cases stated
                                by the law or the present Agreement.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "left"
                                }}
                            >
                                The data retention and protection issues are governed by the Supplement
                                to the Agreement Privacy Policy, which the client read and commits to
                                adhere it.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                The Client undertakes to protect and not to disclose any Passwords,
                                created by them or provided to them under the present Agreement. If the
                                client has not complied with this obligation and/or could, but has not
                                prevented it and/or performed such actions on purpose or due to own
                                negligence, the Client fully assumes the losses and undertakes to
                                reimburse the losses of the persons incurred due to the indicated
                                actions of the Client or their failure to act.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "left"
                                }}
                            >
                                In the event of loss of an Account Password or other Passwords by the
                                Client or the Password(s) are disclosed not due to the fault of the
                                Client or Codego, or in case a real threat has occurred or may occur to
                                the Profile of the Client, the Client undertakes to change the Passwords
                                immediately or, if the client does not have the possibility to do that,
                                notify Codego thereof immediately. Codego shall not be liable for the
                                consequences originating due to the notification failure.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "justify"
                                }}
                            >
                                After Codego receives the notification from the Client, Codego shall
                                immediately suspend access to the Profile of the Client and the
                                provision of Codego services until a new password or created for the
                                Client.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Codego has the right to transmit all collected important information
                                about the Client and their activity to other law enforcement
                                institutions, public authorities and other financial institutions, it
                                such is determined by the legislation, and in order to identify whether
                                this Agreement and relevant legislation have not been or will not be
                                violated.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Under the client’s request, the Client’s data may also be transmitted to
                                payment initiation or account information service institutions. Codego
                                may refuse to provide an account information service provider or a
                                payment initiation service provider with access to the Client’s Account
                                based on objective and duly reasoned grounds relating to unauthorized or
                                unfair access to the Account, gained by that account information service
                                provider or payment initiation service provider, including unauthorized
                                or unfair payment transaction initiation.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={13}>
                            <h2
                                style={{ paddingLeft: "46pt", textIndent: "-30pt", textAlign: "left" }}
                            >
                                Complaints
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                <a href="mailto:complaints@Codego.com" className="a" target="_blank">
                                    If you have a complaint with the Payment Services we provide, please
                                    contact{" "}
                                </a>
                                complaints@Codego.com. If your complaint is unresolved, you may be
                                entitled to referto the Financial Ombudsman Service (
                                <a
                                    href="http://www.ombudsfin.be/fr/particuliers/home)"
                                    className="s3"
                                    target="_blank"
                                >
                                    https://www.ombudsfin.be/fr/particuliers/home
                                </a>
                                <a href="http://www.ombudsfin.be/fr/particuliers/home)" target="_blank">
                                    ).
                                </a>
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={14}>
                            <h2
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "46pt",
                                    textIndent: "-30pt",
                                    textAlign: "left"
                                }}
                            >
                                Amendments
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "justify"
                                }}
                            >
                                The contractual relationship between you and Codego shall commence
                                immediately after you have been successfully registered as a Customer.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Codego is entitled to unilaterally amend the provisions of these General
                                Terms and Conditions. The Client shall be informed of any changes to the
                                General Terms and Conditions by means of a notification on the App and a
                                copy of the new General Terms and Conditions shall be sent to the Client
                                on a durable medium, at his request.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "justify"
                                }}
                            >
                                Unless otherwise agreed, any changes to these General Terms and
                                Conditions or other agreements shall take effect at the earliest within
                                two (2) weeks from the date of their notification to the Customer.
                                notification to the Customer.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "109%",
                                    textAlign: "justify"
                                }}
                            >
                                The Client shall be deemed to have accepted amendments and additions to
                                the Agreement unless it notifies Codego that it does not accept them
                                before the expiry of the notice period, which shall result in the
                                automatic termination of the Agreement.
                            </p>
                            <p
                                style={{
                                    paddingTop: "7pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Immediate or no notice shall be given in case Codego amends in any way
                                or adds any provision to the Agreement where the amendment or addition
                                is required in the event of any changes imposed on Codego by any
                                Competent Authority or any applicable law.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={15}>
                            <h2
                                style={{
                                    paddingTop: "10pt",
                                    paddingLeft: "46pt",
                                    textIndent: "-30pt",
                                    textAlign: "left"
                                }}
                            >
                                Laws and jurisdiction
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingTop: "9pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                The Agreement is governed and shall be construed in accordance with the
                                laws of Belgium.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                style={{
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    lineHeight: "108%",
                                    textAlign: "justify"
                                }}
                            >
                                Any dispute arising out of or in connection with the Agreement which
                                shall not be amicably settled by the Parties through good faith
                                negotiation within three (3) months after notification in writing by any
                                of the parties shall belong to the exclusive jurisdiction of the Courts
                                of Brussels (Belgium), even in case of side claim or counterclaim.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text={16}>
                            <h2
                                style={{
                                    paddingTop: "9pt",
                                    paddingLeft: "46pt",
                                    textIndent: "-30pt",
                                    textAlign: "left"
                                }}
                            >
                                Client assessment
                            </h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l15">
                                <li data-list-text="16.1">
                                    <h3
                                        style={{
                                            paddingLeft: "46pt",
                                            textIndent: "-30pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Assessment of Individual clients
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Individual clients are assessed based on accurate personal
                                        identification details, including full legal name, date of birth,
                                        and a valid residential address.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego conducts a risk-based approach to due diligence, considering
                                        factors such as the customer's location, the nature of their
                                        transactions, and the types of products or services they require.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Depending on the risk level, further verification may be required,
                                        including proof of source of funds, employment details, or tax
                                        returns.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="16.2">
                                    <h3
                                        style={{
                                            paddingLeft: "46pt",
                                            textIndent: "-27pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Assessment of Corporate clients
                                    </h3>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        For corporate customers, the agent must obtain and verify the
                                        business's legal name, registered business address, and official
                                        registration number.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The client must also provide details of the corporate structure,
                                        including ownership and control arrangements, to understand the
                                        beneficial owners of the business. Documents for verification may
                                        include a certificate of incorporation, articles of association, or
                                        a document confirming the business's address, such as a utility bill
                                        or lease agreement.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The specific documentation required for KYB can vary depending on
                                        the country, industry, and type of transaction.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "107%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        For corporate customers, due diligence also involves understanding
                                        the nature of the business, its industry, its source of funds, and
                                        the expected transaction behavior.
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Codego must identify any politically exposed persons
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego reserves the right to the possibility to change the
                                        individual and corporate account, in any moment based on the risk
                                        plan related to the transaction usage and the purpose of IBAN
                                        account declared based on the corporate client identification scale.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Codego reserves the right to automatically freeze the individual and
                                        corporate account to a high-risk category and ask to provide further
                                        documentation related to the business category and transactions made
                                        within the account.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={17}>
                            <h2
                                style={{ paddingLeft: "46pt", textIndent: "-30pt", textAlign: "left" }}
                            >
                                Account balance
                            </h2>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p style={{ paddingLeft: "15pt", textIndent: "0pt", textAlign: "left" }}>
                        If a client's account remains in a negative balance for a continuous period
                        of three months, the account will be subject to closure.
                    </p>
                    <p
                        style={{
                            paddingTop: "7pt",
                            paddingLeft: "15pt",
                            textIndent: "0pt",
                            textAlign: "left"
                        }}
                    >
                        The client will receive prior notification before this action is taken.
                    </p>
                </div>

            </div>
            </BusinessNavbar> */}
            {/* <Footer title={"tc"} /> */}
        </>
    )
}
export default TermsAndConditions
